<!-- @component Embed a form created using Fillout.com as a popup. -->

<script lang="ts">
  import { Dialog, DialogOverlay } from "@rgossiaux/svelte-headlessui";
  import { XIcon } from "lucide-svelte";
  import { createEventDispatcher } from "svelte";
  import { cubicOut } from "svelte/easing";
  import { fade } from "svelte/transition";

  import GlobalHotKey from "../common/GlobalHotKey.svelte";

  export let formId: string;
  export let title: string;
  export let open: boolean;

  const dispatch = createEventDispatcher<{ close: void }>();

  const dim = "max-w-screen-md w-full h-full max-h-[720px]";

  function close() {
    dispatch("close");
  }
</script>

{#if open}
  <GlobalHotKey hotKey="Escape" on:hotkey={close} />
  <Dialog {open} on:close={close} static>
    <div
      class="fixed z-40 inset-0 flex flex-col justify-center items-center p-4"
      transition:fade={{ duration: 500, easing: cubicOut }}
    >
      <DialogOverlay class="absolute inset-0 bg-black/50 -z-10" />
      <div
        class="relative flex flex-col {dim} bg-white/5 border border-white/10 rounded-lg"
      >
        <iframe
          src="https://embed.fillout.com/t/{formId}?fillout-embed-type=popup&fillout-embed-dynamic-resize=true"
          allow="microphone; camera; geolocation"
          class="w-full h-full border-0 bg-black rounded-lg"
          {title}
        />
        <button
          class="absolute w-8 h-8 p-1 -right-3 -top-3 rounded-full bg-black border border-white/20"
          on:click={close}
        >
          <XIcon class="h-full w-full" />
        </button>
      </div>
    </div>
  </Dialog>
{/if}
